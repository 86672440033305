import React, { useState , useRef } from 'react';
import Header from '../components/header';
import PageHeader from '../components/page-header';
import Footer from '../components/footer';
import emailjs from 'emailjs-com';
import config from '../config'

const Contact = () => {
    const formRef = useRef(null); // Using `null` as the initial ref value

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior

        emailjs.sendForm('service_mija5sf', 'template_8tkigrs', e.target, '5byznIDkZSKKzFaSU')
        .then((result) => {
            console.log(result.text);
            setShowSuccessAlert(true);
            formRef.current.reset(); // Reset the form
            setTimeout(() => {
                setShowSuccessAlert(false);
            }, 10000); // Hide success alert after 10 seconds
        }, (error) => {
            console.log(error.text);
            setShowErrorAlert(true);
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 10000); // Hide error alert after 10 seconds
        });
    };
    return (
        <div>
            <Header activeLink="Contact"/>
            <PageHeader title="Contact" />
            <section className="py-5 bg-light shadow-sm z-index-20">
                <div className="container py-5">
                    <div className="card">
                    <div className="card-body p-4 p-lg-5">
                        <div className="row py-5">
                        <div className="col-lg-5">
                            <h2>Request a <span className="text-primary">Call </span>Back</h2>
                            <p className="mb-5 text-muted">For all your framing, drywalling, mudding, tapping, ceiling texture, and insulating needs, trust our experienced team to deliver quality craftsmanship and exceptional service. Contact us today to get started on your project!</p>
                            <ul className="list-unstyled mb-0">
                            <li>
                                <h5 className="text-muted">Address</h5>
                                <p className="text-muted">{config.address}</p>
                            </li>
                            <li>
                                <h5 className="text-muted">Email</h5>
                                <p className="text-muted">{config.emailAddress}</p>
                            </li>
                            <li>
                                <h5 className="text-muted">Phone</h5>
                                <p className="text-muted">{config.phoneNumber}</p>
                                <p className="text-muted">{config.phoneNumber2}</p>
                            </li>
                            </ul>
                        </div>
                        <div className="col-lg-7">
                            <form onSubmit={sendEmail} method="POST" ref={formRef}>
                            <div className="row gy-3">
                                <div className="col-lg-6">
                                <input className="form-control" type="text" name="firstname" placeholder="First name" required />
                                </div>
                                <div className="col-lg-6">
                                <input className="form-control" type="text" name="lastname" placeholder="Last name" required />
                                </div>
                                <div className="col-lg-6">
                                <input className="form-control" type="tel" name="phone" placeholder="Phone number" required />
                                </div>
                                <div className="col-lg-6">
                                <input className="form-control" type="text" name="subject" placeholder="Subject" required />
                                </div>
                                <div className="col-lg-12">
                                <input className="form-control" type="email" name="email" placeholder="Email address" required />
                                </div>
                                <div className="col-lg-12">
                                <textarea className="form-control" name="message" rows="5" placeholder="Leave your message" required></textarea>
                                </div>
                                <div className="col-lg-12">           {showSuccessAlert && (
                                    <div className="alert alert-success" role="alert">
                                        Message sent successfully!
                                    </div>
                                    )}
                                    {showErrorAlert && (
                                        <div className="alert alert-danger" role="alert">
                                            Failed to send the message, please try again.
                                        </div>
                                    )}
                                    <button className="btn btn-outline-primary" type="submit">Submit now</button>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default Contact;
