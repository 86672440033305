import React from 'react';
import ServiceImage2 from '../img/footer.jpg'; 
import LogoWhite from '../img/logo2.jpg'; 
import { Link } from 'react-router-dom'
import config from '../config'

function Footer() {
    const footerNavItems = [
        { path: "/", label: "Home" },
        { path: "/pages/about", label: "About" },
        { path: "/pages/services", label: "Services" },
        { path: "/pages/contact", label: "Contact" }
      ]

  return (
    <footer className="text-white bg-cover bg-center" style={{ background: `url(${ServiceImage2})` }}>
      <div className="dark-overlay">
        <div className="overlay-content">
          <div className="container py-5">
            <div className="row py-5">
              {/* Logo and contact info */}
              <div className="col-lg-4 col-md-6">
                <img className="mb-4" src={LogoWhite} alt="" width="120"/>
                <p className="text-light text-sm mb-4"></p>
                <p className="mb-1"><i className="fas me-3 text-primary fa-fw fa-envelope"></i><span className="text-sm">{config.emailAddress}</span></p>
                <p className="mb-1"><i className="fas me-3 text-primary fa-fw fa-mobile"></i><span className="text-sm">{config.phoneNumber}</span></p>
                <p className="mb-1"><i className="fas me-3 text-primary fa-fw fa-mobile"></i><span className="text-sm">{config.phoneNumber2}</span></p>
                <p className="mb-1"><i className="fas me-3 text-primary fa-fw fa-map-marker-alt"></i><span className="text-sm">{config.address}</span></p>
              </div>
              <div class="col-lg-2 col-md-6">
                <h6 class="text-uppercase text-primary mt-3 mb-4">Quick <span class="text-white">links</span></h6>
                <ul class="list-unstyled">
                  {footerNavItems.map(item => (
                    <li key={item.label} className="mb-2">
                       <Link 
                          className={`footer-link`} 
                          to={item.path}
                        >
                          {item.label}
                        </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div class="col-lg-2 col-md-6">
                <h6 class="text-uppercase text-primary mt-3 mb-4">Our <span class="text-white">services</span></h6>
                <ul class="list-unstyled">
                <li className="mb-2"><Link className="footer-link" to="/pages/services/framing">Framing</Link></li>
                <li className="mb-2"><Link className="footer-link" to="/pages/services/drywall">Drywalling</Link></li>
                <li className="mb-2"><Link className="footer-link" to="/pages/services/mudding">Mudding</Link></li>
                <li className="mb-2"><Link className="footer-link" to="/pages/services/tapping">Tapping</Link></li>
                <li className="mb-2"><Link className="footer-link" to="/pages/services/ceiling">Ceiling Texture</Link></li>
                <li className="mb-2"><Link className="footer-link" to="/pages/services/insulating">Insulating</Link></li>
                </ul>
              </div>
              <div class="col-lg-4 col-md-6">
                <h6 class="text-uppercase text-primary mt-3 mb-4">Opening <span class="text-white">hours</span></h6>
                <ul class="list-unstyled mb-0">
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Monday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Tuesday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Wednesday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Friday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Saturday </p><span class="text-sm mb-0">9:00 am - 06:00 pm</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between pb-2">
                    <p class="text-sm mb-0">Sunday </p><span class="text-sm mb-0 text-uppercase text-primary">Closed</span>
                  </li>
                </ul>
              </div>
              {/* Quick links */}
              {/* ... Other sections ... */}
            </div>
          </div>
          <div className="container text-center">
            <div className="border-top py-4">
              <p className="mb-0 text-white text-sm">&copy; 2024 RA Interiors Ltd. All rights reserved. </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
